






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Category } from '../domain/Category';

@Component
export default class CategoryTable extends Vue {
    @Prop() private readonly categories!: any[];
    @Prop() private readonly isLoading!: boolean;
    @Prop() private readonly pageCount!: number;

    private nameFilter: string = '';

    private search() {
        this.$emit('updateSearch', this.nameFilter);
    }

    private updateRouteFor(category: Category) {
      this.$router.push(`/view-category/${category.id}`);

    }

    private onPageChange(newPage: number) {
        this.$emit('pageChange', newPage - 1); // Lors d'un click sur la page 1, on doit retourner la page 0
    }
}
