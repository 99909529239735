















import { Component, Vue, Prop } from 'vue-property-decorator';
import ListingAddButton from '@/components/ListingAddButton.vue';
import Page from '@/components/Page.vue';
import Card from '@/components/card/Card.vue';
import CategoryTable from '../components/CategoryTable.vue';
import { CategorySearchCriteria } from '../domain/CategorySearchCriteria';
import { Category } from '../domain/Category';
import { CategoryService } from '../services/CategoryService';

@Component({
    components: { Page, ListingAddButton, Card, CategoryTable },
})
export default class CategoryListing extends Vue {
    private categories: Category[] = [];
    private pageCount: number = 1;
    private isLoading: boolean = false;
    private searchCriteria: CategorySearchCriteria = {
        name: '',
        page: 0,
    };

    private async mounted() {
       await this.fetchCategories();
    }

    private async fetchCategories() {
      this.isLoading = true;
      try {
        const { categories, pageCount } = await CategoryService
          .getCategoryFor(this.searchCriteria, this.$store.getters['sessionStore/token']);

        this.categories = categories;
        this.pageCount = pageCount;
      } catch {
        this.$notify.error({
          title: 'Erreur',
          message: 'Il y a eu une erreur lors du chargement de vos catégories. Veuillez réessayer',
        });
      }
      this.isLoading = false;
    }

    private async onSearchUpdate(nameFilter: string) {
        this.searchCriteria.page = 0;
        this.searchCriteria.name = nameFilter;
        await this.fetchCategories();
    }

    private async onPageChange(page: number) {
        this.searchCriteria.page = page;
        await this.fetchCategories();
    }
}
